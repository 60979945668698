/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTimerState = /* GraphQL */ `
  query GetTimerState($id: ID!) {
    getTimerState(id: $id) {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTimerStates = /* GraphQL */ `
  query ListTimerStates(
    $filter: ModelTimerStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimerStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isActive
        isPaused
        state
        mode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderboard = /* GraphQL */ `
  query GetLeaderboard($id: ID!) {
    getLeaderboard(id: $id) {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeaderboards = /* GraphQL */ `
  query ListLeaderboards(
    $filter: ModelLeaderboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeaderboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mode
        playTime
        elapsedTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byModeByElapsedTime = /* GraphQL */ `
  query ByModeByElapsedTime(
    $mode: String!
    $elapsedTime: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaderboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byModeByElapsedTime(
      mode: $mode
      elapsedTime: $elapsedTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mode
        playTime
        elapsedTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

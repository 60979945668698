/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTimerState = /* GraphQL */ `
  subscription OnCreateTimerState(
    $filter: ModelSubscriptionTimerStateFilterInput
  ) {
    onCreateTimerState(filter: $filter) {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTimerState = /* GraphQL */ `
  subscription OnUpdateTimerState(
    $filter: ModelSubscriptionTimerStateFilterInput
  ) {
    onUpdateTimerState(filter: $filter) {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTimerState = /* GraphQL */ `
  subscription OnDeleteTimerState(
    $filter: ModelSubscriptionTimerStateFilterInput
  ) {
    onDeleteTimerState(filter: $filter) {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLeaderboard = /* GraphQL */ `
  subscription OnCreateLeaderboard(
    $filter: ModelSubscriptionLeaderboardFilterInput
  ) {
    onCreateLeaderboard(filter: $filter) {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLeaderboard = /* GraphQL */ `
  subscription OnUpdateLeaderboard(
    $filter: ModelSubscriptionLeaderboardFilterInput
  ) {
    onUpdateLeaderboard(filter: $filter) {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLeaderboard = /* GraphQL */ `
  subscription OnDeleteLeaderboard(
    $filter: ModelSubscriptionLeaderboardFilterInput
  ) {
    onDeleteLeaderboard(filter: $filter) {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
